<style scoped>
    #login-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 70vw;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }

    #blue-filter {
        position: fixed;
        top: 0;
        left: 0;
        width: 70vw;
        height: 100vh;
        background-color: rgba(13, 59, 112, 0.15);
    }

    #white-rectangle {
        position: fixed;
        background-color: white;
        top: -10vh;
        right: -10vw;
        width: 50vw;
        height: 120vh;
        transform: rotate(-8deg);
        box-shadow: -10px 0 20px rgba(0, 10, 20, 0.2);
        overflow: hidden;
    }

    #blue-line {
        position: absolute;
        left: 0;
        top: 0;
        height: 10.5vh;
        width: 100%;
        background: linear-gradient(90deg, #0d3b70, #1e73be 75%);
        transform: rotate(8deg);
    }

    #blue-line-mobile {
        display: none;
    }

    #logo-container {
        position: relative;
        width: 65%;
        height: auto;
        margin-bottom: 6vh;
    }

    #logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    #login-form {
        width: 41vw;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8vh;
        position: relative;
    }

    .inputeo {
        width: 25vw;
        background: none !important;
    }

    .inputeo input {
        font-weight: 500;
        color: rgb(50, 60, 70);
        font-size: 18px;
    }

    .inputeo .fa-icon {
        color: #1e73be;
    }

    .inputeo label {
        font-size: 13px;
    }

    #spindle {
        width: 45%;
        height: auto;
        object-fit: cover;
        position: relative;
        opacity: 0.2;
        display: block;
        margin: 3vh auto 0 auto;
    }

    #login-submit-button {
        background: linear-gradient(90deg, #ff6b00, #ff9100);
        border-radius: 4px;
        color: white;
        height: auto;
        border: none;
        position: relative;
        text-transform: uppercase;
        padding: 12px 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 3vh;
        cursor: pointer;
        transition: 0.15s ease;
    }

    #login-submit-button:hover {
        opacity: 0.9;
    }

    #login-submit-button .fa-icon {
        width: 20px;
        height: 20px;
        color: white;
        margin-left: 8px;
    }

    #password-forgotten {
        margin: 30px 10px 0px 10px;
        color: #ff9100;
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (max-width: 1700px) {
        #logo-container {
            width: 70%;
        }
    }

    @media screen and (max-width: 1500px) {
        #logo-container {
            width: 75%;
        }
    }

    @media (max-width: 768px) {
        #logo-container {
            width: 70%;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
        }

        #login-background {
            width: 100%;
            height: 100px;
            object-fit: cover;
            object-position: 50% 70%;
            display: block;
            position: unset;
        }

        #blue-line-mobile {
            display: block;
            left: 0;
            top: 0;
            height: 6px;
            width: 100%;
            background: linear-gradient(90deg, #1e73be, #00a1cf);
        }

        #spindle {
            width: 170px;
            height: auto;
            object-fit: cover;
            position: relative;
            margin-top: 10px;
            margin-bottom: 50px;
            opacity: 0.2;
        }

        #login-form {
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 25px;
            margin-left: 0;
        }

        #logo {
            position: relative;
            width: 250px;
            height: auto;
            margin-bottom: 25px;
        }

        #white-rectangle {
            display: none;
        }

        #blue-filter {
            display: none;
        }

        #login-submit-button {
            height: auto;
            border: none;
            position: relative;
            text-transform: uppercase;
            padding: 12px 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            font-size: 15px;
            margin-top: 15px;
        }

        .inputeo {
            min-width: calc(100% - 40px);
            width: calc(100% - 40px);
        }
    }
</style>

<template>
    <picture>
        <source srcset="../../assets/images/login-background.webp" type="image/webp">
        <source srcset="../../assets/images/login-background.jpg" type="image/jpg">
        <img id="login-background" src="../../assets/images/login-background.webp" alt="Photo de broche">
    </picture>

    <div id="blue-filter"></div>
    <div id="white-rectangle">
        <div id="blue-line"></div>
    </div>
    <div id="blue-line-mobile"></div>

    <div id="login-form">
        <div id="logo-container">
            <picture>
                <source srcset="../../assets/images/logo.webp" type="image/webp">
                <source srcset="../../assets/images/logo.png" type="image/png">
                <img id="logo" src="../../assets/images/logo.webp" alt="Logo de Electrobroche Concept" width="512px" height="187px">
            </picture>
        </div>

        <BaseInput
            v-model="account.username"
            label="Nom d'utilisateur"
            type="text"
            name="username"
            validator="text"
            :error="formErrors?.username?.error?.message"
            @onChange="onInputChange"
            @keyup="handleKeyUp"
            :displayError="displayError"
        >
            <template v-slot:iconBefore>
                <font-awesome-icon class="fa-icon" :icon="['fas', 'user']" />
            </template>
        </BaseInput>

        <BaseInput
            v-model="account.password"
            label="Mot de passe"
            type="password"
            name="password"
            validator="text"
            :error="formErrors?.password?.error?.message"
            @onChange="onInputChange"
            @keyup="handleKeyUp"
            :displayError="displayError"
        >
            <template v-slot:iconBefore>
                <font-awesome-icon class="fa-icon" :icon="['fas', 'key']" />
            </template>
        </BaseInput>

        <BaseButton id="login-submit-button" buttonText="Connexion" @click="logIn">
            <template v-slot:iconAfter>
                <font-awesome-icon class="fa-icon" :icon="['fas', 'sign-in-alt']" />
            </template>
        </BaseButton>

        <div id="password-forgotten" @click="passwordForgotten" v-if="!$store.getters.isFromAppTablet">Mot de passe oublié ?</div>

        <picture>
            <source srcset="../../assets/images/spindle.webp" type="image/webp">
            <source srcset="../../assets/images/spindle.png" type="image/png">
            <img id="spindle" src="../../assets/images/spindle.webp" alt="Photo de broche" width="354px" height="250px">
        </picture>

        <Dialog ref="dialog" />
    </div>
</template>

<script>
    import BaseInput from '../../components/Base/BaseInput.vue'
    import BaseButton from '../../components/Base/BaseButton.vue'
    import Dialog from '../../components/App/Dialog.vue'
    import axios from 'axios'
    import { createToast } from 'mosha-vue-toastify';
    import { AUTHENTICATION_LOGIN, AUTHENTICATION_TAG } from '../../store/actions/authentication';

    export default {
        name: 'Login',
        components: {
            BaseInput,
            BaseButton,
            Dialog
        },
        data() {
            return {
                account: {
                    username: '',
                    password: ''
                },
                displayError: false,
                formErrors: {}
            }
        },
        mounted() {
            const that = this;

            // Événement envoyé par l'application tablette lors de l'authentification par badge NFC
            document.addEventListener('nfcLogIn', function(data) {
                if (!that.$store.getters.isAuthenticated) {
                    that.$store.dispatch(AUTHENTICATION_TAG, { tagId: data.detail.tagId })
                    .then(() => {
                        that.$router.push('/');
                    })
                    .catch(() => {});
                }
            });
        },
        methods: {
            logIn() {
                if (!this.checkForm()) return;

                const { username, password } = this.account;
                this.$store.dispatch(AUTHENTICATION_LOGIN, { username, password })
                .then(() => {
                    this.$router.push('/');
                })
                .catch(() => {});
            },
            passwordForgotten() {
                this.$refs.dialog.show({
                    type: 'prompt',
                    promptMode: 'input',
                    title: 'Mot de passe oublié',
                    label: 'Identifiant ou adresse email',
                    message: `Merci de spécifier votre identifiant ou votre adresse email :`
                }).then((input) => {
                    if (input == null || input.trim().length === 0) {
                        createToast(`Merci de saisir votre identifiant ou votre adresse email`, {
                            position: 'bottom-right',
                            type: 'danger',
                            hideProgressBar: true,
                            showIcon: true,
                            transition: 'slide'
                        });
                    } else {
                        axios
                        .post('account/requestResetPassword', {usernameOrEmail: input}, { 
                            toastSuccessMessage: `Un lien de regénération vous a été envoyé par email`, 
                            toastError: true, 
                            showPreloader: true 
                        })
                        .catch(() => {});
                    }
                })
                .catch(() => {});
            },
            onInputChange(input) {
                if (input.error.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            handleKeyUp(event) {
                if (event.keyCode === 13) this.logIn();
            }
        }
    }
</script>